export const SET_TOOL = 'SET_TOOL';
export const SET_CURSOR_POSITION = 'SET_CURSOR_POSITION';
export const SET_MOUSE_DOWN = 'SET_MOUSE_DOWN';
export const SET_ANCHOR = 'SET_ANCHOR';
export const UPDATE_EDGES = 'UPDATE_EDGES';
export const UPDATE_WALLS = 'UPDATE_WALLS';
export const DELETE_WALLS = 'DELETE_WALLS';
export const CREATE_WALLS = 'CREATE_WALLS';
export const ADD_TEXT = 'ADD_TEXT';
export const DELETE_TEXT = 'DELETE_TEXT';
export const SET_CUR_SHAPE = 'SET_CUR_SHAPE';
export const UPDATE_SELECTED = 'UPDATE_SELECTED';
export const SET_SCALE = 'SET_SCALE';
export const ADD_OBJECT = 'ADD_OBJECT';
export const DELETE_OBJECT = 'DELETE_OBJECT';
export const SET_NEW_FILE = 'SET_NEW_FILE';
export const UPDATE_OBJECT = 'UPDATE_OBJECT';
export const SET_WALL = 'SET_WALL';
export const LOAD_FILE = 'LOAD_FILE';
export const UPDATE_TEXT = 'UPDATE_TEXT';